import store from '@/store'
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import * as api from '@/api/permission'

@Module({ dynamic: true, store, name: "permission", namespaced: true })
class PermissionStore extends VuexModule {
    approvedByList: any[] = [];
    adminList: any[] = [];

    @Mutation
    SET_APPROVEDBYLIST(data: any) {
        this.approvedByList = data.result;
    }

    @Mutation
    SET_ADMINLIST(data: any) {
        this.adminList = data.result;
    }

    @Action({ commit: "SET_APPROVEDBYLIST" })
    public async getApprovalBaseAll(params: any) {
        let result = await api.getApprovalBaseAll(params);
        return result.data;
    }

    @Action
    public async batchAddApprovedBy(data: any) {
        let result = await api.batchAddApprovedBy(data);
        return result.data;
    }

    @Action
    public async batchDelApprovedBy(data: any) {
        let result = await api.batchDelApprovedBy(data);
        return result.data;
    }

    @Action({ commit: "SET_ADMINLIST" })
    public async getMeetingAdminAll() {
        let result = await api.getMeetingAdminAll();
        return result.data;
    }

    @Action
    public async batchAddAdmin(data: any) {
        let result = await api.batchAddAdmin(data);
        return result.data;
    }

    @Action
    public async batchDelAdmin(data: any) {
        let result = await api.batchDelAdmin(data);
        return result.data;
    }

    @Action
    public async batchToggleActive(data: any) {
        let result = await api.batchToggleActive(data);
        return result.data;
    }
}

export default getModule(PermissionStore);