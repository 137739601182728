import ajax from '@/util/ajax'

/**查询基地审批人*/
export const getApprovalBaseAll = (params: any) => ajax({
    url: "/api/services/app/PermissionSetting/GetApprovalBaseAll",
    method: "get",
    params
});

/**新增基地审批人*/
export const batchAddApprovedBy = (data: any) => ajax({
    url: "/api/services/app/PermissionSetting/BatchAddApprovedBy",
    method: "post",
    data
});

/**批量删除基地审批人*/
export const batchDelApprovedBy = (data: any) => ajax({
    url: "/api/services/app/PermissionSetting/BatchDelApprovedBy",
    method: "delete",
    data
});

/**查询管理员*/
export const getMeetingAdminAll = () => ajax({
    url: "/api/services/app/PermissionSetting/GetMeetingAdminAll",
    method: "get"
});

/**新增管理员*/
export const batchAddAdmin = (data: any) => ajax({
    url: "/api/services/app/PermissionSetting/BatchAddAdmin",
    method: "post",
    data
});

/**批量删除*/
export const batchDelAdmin = (data: any) => ajax({
    url: "/api/services/app/PermissionSetting/BatchDelAdmin",
    method: "delete",
    data
});

/**批量启用禁用管理员*/
export const batchToggleActive = (data: any) => ajax({
    url: "/api/services/app/PermissionSetting/BatchToggleActive",
    method: "put",
    data
});