




















































































































































import { Vue, Component } from 'vue-property-decorator'
import BaseDataModule from '@/store/modules/base-data'
import PermissionModule from '@/store/modules/permission'
import Collective from '@/views/components/Collective.vue'

@Component({ components: { Collective } })
export default class PermissionSetting extends Vue {
  dataLoading: boolean = true;
  showApprovedBy: boolean = false;
  showAdminBy: boolean = false;

  orgId: number = 0;
  orgName: string = "";
  selectedIds: number[] = [];
  activeName: string = "first";

  get approvedByList() {
    return PermissionModule.approvedByList;
  }

  get adminList() {
    return PermissionModule.adminList;
  }

  get orgList() {
    return BaseDataModule.orgList;
  }

  get isAdmin() {
    return BaseDataModule.currentUser.isAdmin;
  }

  async queryApprovalBase() {
    await PermissionModule.getApprovalBaseAll({ orgId: this.orgId }).finally(() => {
      this.dataLoading = false;
    });
  }

  async queryAdmin() {
    await PermissionModule.getMeetingAdminAll().finally(() => {
      setTimeout(() => {
        this.dataLoading = false;
      }, 500);
    });
  }

  async approvedByCallback(value: any[]) {
    if (value.length <= 0) return;

    let _data = value.map(t => ({
      creatorUserId: BaseDataModule.currentUser.employeeID,
      approvedById: t.employeeID,
      approvedByName: t.employeeName,
      organId: this.orgId,
      organName: this.orgName
    }));

    await PermissionModule.batchAddApprovedBy(_data).then(res => {
      this.queryApprovalBase();
    })
  }

  async adminCallback(value: any[]) {
    if (value.length <= 0) return;

    let _data = value.map(t => ({
      creatorUserId: BaseDataModule.currentUser.employeeID,
      AdminById: t.employeeID,
      AdminByName: t.employeeName,
      IsActive: 1
    }));

    await PermissionModule.batchAddAdmin(_data).then(res => {
      this.queryAdmin();
    })
  }

  handleSelectChange(val: any[]) {
    this.selectedIds = val.map(t => { return t.id });
  }

  async handleClick(tab: any, event: any) {
    if (this.activeName == "second") {
      this.dataLoading = true;
      this.queryAdmin();
    }
  }

  async batchDelApprovedBy() {
    if (this.selectedIds.length <= 0) {
      this.$message.warning("请勾选择要取消权限的数据");
      return;
    }

    this.$confirm("确定取消权限？", "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(async () => {
      await PermissionModule.batchDelApprovedBy(this.selectedIds);
      this.$message.success("操作成功");
      this.queryApprovalBase();
    }).catch(e => { console.log(e); });
  }

  async batchDelAdmin() {
    if (this.selectedIds.length <= 0) {
      this.$message.warning("请勾选择要删除的数据");
      return;
    }

    this.$confirm("确定删除？", "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(async () => {
      await PermissionModule.batchDelAdmin(this.selectedIds);
      this.$message.success("操作成功");
      this.queryAdmin();
    }).catch(e => { console.log(e); });
  }

  async delApprovedBy(row: any) {
    await PermissionModule.batchDelApprovedBy([row.id]).then(res => {
      this.queryApprovalBase();
    })
  }

  async delAdmin(row: any) {
    await PermissionModule.batchDelAdmin([row.id]).then(res => {
      this.queryAdmin();
    })
  }

  async toggleActiveAdmin(row: any) {
    await PermissionModule.batchToggleActive({
      ids: [row.id],
      isActive: !row.isActive
    }).then(res => {
      this.queryAdmin();
    });
  }

  async batchToggleActiveAdmin(state: boolean) {
    let textFlag = state ? "启用" : "禁用";
    if (this.selectedIds.length <= 0) {
      this.$message.warning(`请勾选择要${textFlag}的数据`);
      return;
    }

    this.$confirm(`确定批量${textFlag}？`, "温馨提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(async () => {
      await PermissionModule.batchToggleActive({
        ids: this.selectedIds,
        isActive: state
      }).then(res => {
        this.$message.success("操作成功");
        this.queryAdmin()
      });
    }).catch(e => { console.log(e); });
  }

  async mounted() {
    let userId = this.$route.query.id;
    if (userId) {
      await BaseDataModule.getEmployeeById({ employeeId: userId });
    }

    await BaseDataModule.getOrganizationList();
    await BaseDataModule.getAllOrgansOfTheGroup();

    this.orgId = this.orgList[0].id;
    this.orgName = this.orgList[0].fullName;
    this.queryApprovalBase();

    setTimeout(async () => {
      await BaseDataModule.getSameDeptStaffData({ deptId: BaseDataModule.currentUser.deptID });
    }, 1000);
  }

  async setOrg(item: any) {
    this.orgId = item.id;
    this.orgName = item.fullName;
    this.queryApprovalBase();
  }
}
